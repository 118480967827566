"use client";
import { useEffect, useRef } from "react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import Banner from "../components/common/Banner";
import Blogs from "../components/common/Blogs";
import Footer from "../components/common/Footer";
import FAQ from "../components/MainPage/FAQ";
import dynamic from "next/dynamic";
import HowItWorks from "../components/MainPage/HowItWorks";
import OurPlans from "../components/MainPage/OurPlans";
import Testimonials from "../components/MainPage/Testimonials";
import Features from "../components/common/Features";
import { BANNER_ONE, BANNER_TWO } from "../constants/data";

// Replace static import with dynamic
const Hero = dynamic(() => import("../components/MainPage/Hero"), {
  loading: () => (
    <div className="animate-pulse bg-secondary h-[600px] rounded-[30px]" />
  ),
  ssr: true,
});

export default function Home() {
  const tawkMessengerRef = useRef();

  // Scroll to section handling
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const section = urlParams.get("section");
    if (section) {
      const navHeight = 100; // 20px padding-top from PageWrapper
      const elementPosition = document
        .getElementById(section)
        .getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - navHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <div
      id="home"
      className="bg-gradient-to-b from-white to-primary50/20 min-h-screen"
    >
      {/* Hero Section */}
      <div className="relative px-5 md:px-20">
        {/* Main Content Container */}
        <section className="md:mt-0 max-w-[1920px] mx-auto">
          <Hero />
        </section>
        <div className="space-y-16 md:space-y-[90px] max-w-[1920px] mx-auto relative z-10">
          {/* How It Works Section */}
          <section>
            <HowItWorks />
          </section>

          {/* Our Plans Section */}
          <section>
            <OurPlans />
          </section>

          {/* Demo Section */}
          <section>
            <Features />
          </section>

          {/* Testimonials Section */}
          <section>
            <Testimonials />
          </section>
          {/* Blogs Section */}
          <section>
            <Blogs />
          </section>

          {/* Banners and Blogs Section */}
          <section>
            <Banner
              heading={BANNER_ONE.heading}
              description={BANNER_ONE.description}
              button
            />
          </section>
          {/* FAQ Section */}
          <section>
            <FAQ />
          </section>
          {/* Play Store and App Store Download Section */}
          <section className="mb-5">
            <Banner
              heading={BANNER_TWO.heading}
              description={BANNER_TWO.description}
            />
          </section>
        </div>

        {/* Decorative Background Elements */}
        <div
          className="absolute top-0 right-0 w-1/2 h-1/2 bg-primary100 
          rounded-full blur-[150px] opacity-20 -z-1"
        />
        <div
          className="absolute bottom-0 left-0 w-1/2 h-1/2 bg-primary200 
          rounded-full blur-[150px] opacity-20 -z-1"
        />
      </div>
      <TawkMessengerReact
        propertyId="674726234304e3196ae95e8b"
        widgetId="1idmupvil"
        useRef={tawkMessengerRef}
      />
      {/* Footer */}
      <footer className="mt-16 md:mt-[90px]">
        <Footer />
      </footer>
    </div>
  );
}
