import { HOW_IT_WORKS } from "../../constants/data";


export default function HowItWorks() {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const cardVariants = {
    hidden: {
      opacity: 0,
      y: 20
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  return (
    <div
      id="howitworks"
      className="section-gap"
    >
      {/* Background Decoration */}
      <div className="absolute top-0 right-0 w-64 h-64 bg-primary200 rounded-full 
        blur-3xl opacity-30 -z-1 transform translate-x-1/2 -translate-y-1/2" />
      <div className="absolute bottom-0 left-0 w-64 h-64 bg-primary200 rounded-full 
        blur-3xl opacity-30 -z-1 transform -translate-x-1/2 translate-y-1/2" />

      {/* Heading */}
      <div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="flex flex-col items-center mb-10 md:mb-16"
      >
        <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-primary 
          tracking-tight text-center relative">
          {HOW_IT_WORKS.heading}
          <div className="absolute -bottom-4 left-1/2 transform -translate-x-1/2 
            w-20 h-1 bg-white rounded-full" />
        </h2>
      </div>

      {/* Cards */}
      {HOW_IT_WORKS.cards.map((keyPointGroup, index) => (
        <div
          key={index}
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-100px" }}
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-10"
        >
          {Object.values(keyPointGroup).map((keyPoint, keyPointIndex) => (
            <div
              key={keyPointIndex}
              variants={cardVariants}
              className="w-full group"
            >
              <div className="bg-white/80 backdrop-blur-sm rounded-2xl p-6 md:p-8 
                flex flex-col items-center text-center h-full transform transition-all 
                duration-300 hover:shadow-xl hover:-translate-y-1 relative overflow-hidden
                border border-primary200/30"
              >
                {/* Number Badge */}
                <div className="bg-primary rounded-2xl w-12 md:w-16 h-12 md:h-16 
                  flex items-center justify-center mb-6 transform transition-transform 
                  duration-300 group-hover:scale-110 relative z-10"
                >
                  <span className="text-xl md:text-2xl font-bold text-secondary">
                    {keyPoint.id}
                  </span>
                </div>

                {/* Title */}
                <h4 className="text-xl md:text-2xl font-semibold text-primary mb-4 
                  relative z-10 transition-colors duration-300 group-hover:text-primary900">
                  {keyPoint.title}
                </h4>

                {/* Description */}
                <p className="text-sm md:text-base text-gray-600 leading-relaxed 
                  relative z-10 transition-colors duration-300 group-hover:text-gray-700">
                  {keyPoint.description}
                </p>

                {/* Decorative Background */}
                <div className="absolute top-0 right-0 w-32 h-32 bg-primary200/20 
                  rounded-full blur-2xl transform translate-x-1/2 -translate-y-1/2 
                  transition-all duration-300 group-hover:scale-150" />
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
