import { Dialog, DialogTitle } from '@headlessui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { IoClose } from 'react-icons/io5';
// import Image from 'next/image'; // Commented out for now

export default function ServiceDetailsModal({ isOpen, onClose, services, featureTitle, onContactUs }) {
  return (
    <AnimatePresence>
      {isOpen && (
        <Dialog
          static
          as={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          open={isOpen}
          onClose={onClose}
          className="fixed inset-0 inset-y-24 z-50 overflow-y-auto"
        >
          {/* Backdrop with click handler */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
            className="fixed inset-0  bg-black/30 backdrop-blur-sm cursor-pointer"
            aria-hidden="true"
          />

          {/* Modal Container - Stop event propagation */}
          <div className=" px-4 text-center">
            <span className="inline-block  align-middle" aria-hidden="true">&#8203;</span>

            {/* Modal Content - Stop event propagation */}
            <motion.div
              initial={{ opacity: 0, scale: 0.95, y: 20 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.95, y: 20 }}
              transition={{ duration: 0.2 }}
              onClick={(e) => e.stopPropagation()}
              className="inline-block w-full max-w-2xl p-6 my-8 text-left align-middle 
                bg-white rounded-2xl shadow-xl transform transition-all relative"
            >
              {/* Close Button */}
              <button
                onClick={onClose}
                className="absolute top-4 right-4 p-2 rounded-full hover:bg-gray-100
                  transition-colors duration-200"
              >
                <IoClose className="w-5 h-5 text-gray-500" />
              </button>

              {/* Header */}
              <div className="mb-6 flex-shrink-0">
                <DialogTitle className="text-2xl font-bold text-gray-900">
                  {featureTitle}
                </DialogTitle>
                <p className="mt-2 text-gray-600">
                  Available maintenance services in this package
                </p>
              </div>

              {/* Services Grid - Add overflow */}
              <div className="overflow-y-auto flex-grow max-h-[500px]">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {services.map((service, index) => (
                    <motion.div
                      key={service.id}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.1 }}
                      className="bg-gray-50 rounded-xl p-4 hover:shadow-md transition-shadow
                        duration-300 border border-gray-100"
                    >
                      <div className="flex flex-col gap-2">
                        {/* Service Icon Section - Commented out for now */}
                        {/* <div className="w-12 h-12 rounded-full bg-primary/10 flex-shrink-0
                          flex items-center justify-center">
                              <Image
                                  src={service.icon || '/default-service-icon.svg'}
                                  alt={service.name}
                                  width={24}
                                  height={24}
                                  className="text-primary"
                              />
                          </div> */}

                        {/* Service Details */}
                        <div className="flex-1">
                          <h3 className="text-lg font-semibold text-gray-900 mb-1">
                            {service.name}
                          </h3>
                          <p className="text-sm text-gray-600 line-clamp-2">
                            {service.description}
                          </p>

                          {/* Service Highlights */}
                          {service.highlights && (
                            <div className="mt-3 flex flex-wrap gap-2">
                              {service.highlights.map((highlight, idx) => (
                                <span
                                  key={idx}
                                  className="inline-flex items-center px-2.5 py-0.5 rounded-full
                                    text-xs font-medium bg-primary/10 text-primary"
                                >
                                  {highlight}
                                </span>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>

              {/* Footer */}
              <div className="mt-6 pt-4 border-t border-gray-100 flex-shrink-0">
                <button
                  onClick={() => {
                    onClose(); // Close the service modal
                    onContactUs(); // Open the enquiry form
                  }}
                  className="w-full text-sm text-gray-500 hover:text-primary
                                        transition-colors duration-200 text-center
                                        group flex items-center justify-center gap-2"
                >
                  Contact us for detailed information about these services
                  <motion.span
                    initial={{ opacity: 0, x: -10 }}
                    animate={{ opacity: 1, x: 0 }}
                    className="text-primary opacity-0 group-hover:opacity-100
                                            transition-opacity duration-200"
                  >
                    →
                  </motion.span>
                </button>
              </div>
            </motion.div>
          </div>
        </Dialog>
      )}
    </AnimatePresence>
  );
} 