import Image from "next/image";

export default function Features() {
  return (
    <div className="section-gap">
      {/* Background Decoration */}
      <div className="absolute inset-0 bg-gradient-to-br from-[#B5E4CA]/40 to-[#B5E4CA]/10" />

      <div className="relative z-10 max-w-8xl mx-auto px-4">
        {/* Main Heading */}
        <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-primary tracking-tight text-center mb-16">
          With Advanced Pre-built Features,
          <span className="block mt-2">Just As You Need</span>
          <div className="absolute left-1/2 transform -translate-x-1/2 w-24 h-1 bg-white rounded-full mt-6" />
        </h2>

        {/* Center Image */}
        <div className="relative max-w-2xl mx-auto flex items-center justify-center">
          <Image
            src="/advanced_features.svg"
            alt="Features illustration"
            className=""
            width={500}
            height={500}
          />

          {/* Feature Callouts */}
        </div>
      </div>
    </div>
  );
}
