"use client";

import { useRef, useState } from "react";
import { TESTIMONIALS } from "../../constants/data";
import Image from "next/image";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Slider from "react-slick";
import { motion, AnimatePresence } from "framer-motion";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Fetch testimonials data
const fetchTestimonial = async () => {
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_API_URL}/admin/testimonial/?status=approved`
  );
  return response.data.result.docs;
};

export default function Testimonials() {
  const sliderRef = useRef(null); // Use a ref to access the slider instance
  const [scrollDisabled, setScrollDisabled] = useState({
    left: true,
    right: false,
  });

  const {
    isLoading,
    error,
    data: testimonials,
  } = useQuery({
    queryKey: ["testimonial"],
    queryFn: fetchTestimonial,
  });

  // Slick slider settings
  const settings = {
    dots: false,
    infinite: testimonials?.length > 2, // Only enable infinite scroll if there are more than 2 testimonials
    speed: 500,
    slidesToShow: Math.min(2, testimonials?.length || 2), // Show maximum of 2 slides or total available
    slidesToScroll: 1, // Changed to 1 for smoother navigation
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: testimonials?.length > 1, // Only enable infinite scroll if there are more than 1 testimonial
        },
      },
    ],
    beforeChange: (current, next) => {
      const totalSlides = testimonials?.length || 0;
      const maxSlides = totalSlides > 1 ? totalSlides - 2 : 0;
      setScrollDisabled({
        left: next === 0,
        right: next >= maxSlides,
      });
    },
  };

  // Custom scrollHandler for buttons
  const scrollHandler = (direction) => {
    if (direction === "left") {
      sliderRef.current.slickPrev(); // Trigger the previous slide
    } else {
      sliderRef.current.slickNext(); // Trigger the next slide
    }
  };
  const skeletonLoader = (
    <>
      {/* Single skeleton for small screens */}
      <div className="bg-primary100 rounded-[20px] px-5 pt-5 pb-8 flex flex-row w-full animate-pulse mx-2 md:hidden">
        <div className="flex items-start gap-4">
          <div className="flex items-center gap-[22px] mb-3">
            <div>
              <div className="w-[60px] h-[60px] bg-gray-300 rounded-full"></div>
              <div className="w-full h-5 bg-gray-300 rounded-full mt-4"></div>
            </div>
            <div className="flex flex-col items-start space-y-2">
              <div className="w-24 h-4 bg-gray-300 rounded"></div>
              <div className="w-20 h-3 bg-gray-300 rounded"></div>
            </div>
          </div>
          <div className="space-y-2">
            <div className="w-full h-3 bg-gray-300 rounded"></div>
            <div className="w-3/4 h-3 bg-gray-300 rounded"></div>
          </div>
        </div>
      </div>

      {/* Two skeletons for medium and larger screens */}
      <div className="hidden md:flex gap-4">
        <div className="bg-primary100 rounded-[20px] px-5 pt-5 pb-8 flex flex-row w-1/2 animate-pulse mx-2">
          <div className="flex items-start gap-4">
            <div className="flex items-center gap-[22px] mb-3">
              <div>
                <div className="w-[60px] md:w-[100px] h-[60px] md:h-[100px] bg-gray-300 rounded-full"></div>
                <div className="w-full h-5 bg-gray-300 rounded-full mt-4"></div>
              </div>
              <div className="flex flex-col items-start space-y-2">
                <div className="w-24 h-4 bg-gray-300 rounded"></div>
                <div className="w-20 h-3 bg-gray-300 rounded"></div>
              </div>
            </div>
            <div className="space-y-2">
              <div className="w-full h-3 bg-gray-300 rounded"></div>
              <div className="w-3/4 h-3 bg-gray-300 rounded"></div>
            </div>
          </div>
        </div>

        <div className="bg-primary100 rounded-[20px] px-5 pt-5 pb-8 flex flex-row w-1/2 animate-pulse mx-2">
          <div className="flex items-start gap-4">
            <div className="flex items-center gap-[22px] mb-3">
              <div>
                <div className="w-[60px] md:w-[100px] h-[60px] md:h-[100px] bg-gray-300 rounded-full"></div>
                <div className="w-full h-5 bg-gray-300 rounded-full mt-4"></div>
              </div>
              <div className="flex flex-col items-start space-y-2">
                <div className="w-24 h-4 bg-gray-300 rounded"></div>
                <div className="w-20 h-3 bg-gray-300 rounded"></div>
              </div>
            </div>
            <div className="space-y-2">
              <div className="w-full h-3 bg-gray-300 rounded"></div>
              <div className="w-3/4 h-3 bg-gray-300 rounded"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const itemVariants = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, margin: "-100px" }}
      transition={{ duration: 0.6 }}
      className="section-gap !bg-primary300"
      id="testimonials"
    >
      {/* Background Decoration */}
      <div
        className="absolute top-0 right-0 w-64 h-64 bg-primary500 rounded-full 
        blur-3xl opacity-30 -z-1 transform translate-x-1/2 -translate-y-1/2"
      />
      <div
        className="absolute bottom-0 left-0 w-64 h-64 bg-primary500 rounded-full 
        blur-3xl opacity-30 -z-1 transform -translate-x-1/2 translate-y-1/2"
      />

      {/* Header Section */}
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="flex flex-col items-center mb-10 md:mb-16"
      >
        <h2
          className="text-3xl md:text-4xl lg:text-5xl font-bold text-primary 
          tracking-tight text-center relative"
        >
          {TESTIMONIALS.heading}
          <div
            className="absolute -bottom-4 left-1/2 transform -translate-x-1/2 
            w-20 h-1 bg-white rounded-full"
          />
        </h2>
      </motion.div>

      {/* Navigation Buttons */}
      <motion.div
        variants={itemVariants}
        className="flex justify-end gap-3 mb-8"
      >
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => scrollHandler("left")}
          className={`bg-primary w-12 h-12 rounded-full flex items-center justify-center 
            cursor-pointer shadow-lg transition-all duration-300
            ${
              scrollDisabled.left
                ? "opacity-50 cursor-not-allowed"
                : "hover:shadow-xl"
            }`}
          disabled={scrollDisabled.left}
          aria-label="Previous testimonial"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="text-primary600"
            viewBox="0 0 24 24"
          >
            <polyline points="15 18 9 12 15 6"></polyline>
          </svg>
        </motion.button>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => scrollHandler("right")}
          className={`bg-primary w-12 h-12 rounded-full flex items-center justify-center 
            cursor-pointer shadow-lg transition-all duration-300
            ${
              scrollDisabled.right
                ? "opacity-50 cursor-not-allowed"
                : "hover:shadow-xl"
            }`}
          disabled={scrollDisabled.right}
          aria-label="Next testimonial"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="text-primary600"
            viewBox="0 0 24 24"
          >
            <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
        </motion.button>
      </motion.div>

      {/* Loading and Error States */}
      {isLoading && (
        <motion.div variants={itemVariants} className="w-full">
          {skeletonLoader}
        </motion.div>
      )}

      {error && (
        <motion.div
          variants={itemVariants}
          className="text-center text-red-500"
        >
          {error.message}
        </motion.div>
      )}

      {/* Testimonials Slider */}
      <AnimatePresence>
        <motion.div variants={itemVariants}>
          <Slider
            ref={sliderRef}
            {...settings}
            className="testimonial-container"
          >
            {testimonials?.map((testimonial, index) => (
              <motion.div key={index} variants={itemVariants} className="px-2">
                <motion.div
                  whileHover={{ y: -5 }}
                  className="bg-primary100 rounded-[20px] p-6 md:p-8 
                    shadow-lg hover:shadow-xl transition-all duration-300"
                >
                  <div className="flex flex-col gap-6">
                    {/* Profile Section */}
                    <motion.div
                      className="flex items-center gap-4"
                      initial={{ opacity: 0, x: -20 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.2 }}
                    >
                      <Image
                        src={testimonial.image.location}
                        width={100}
                        height={100}
                        alt={testimonial.name}
                        className="w-[60px] md:w-[100px] h-[60px] md:h-[100px] 
                          rounded-full object-cover shadow-md"
                      />
                      <div>
                        <h4 className="text-lg md:text-xl font-semibold text-primary">
                          {testimonial.name}
                        </h4>
                        <p className="text-sm text-gray-600">
                          {testimonial.designation}
                        </p>
                      </div>
                    </motion.div>
                    <div className="relative group">
                      <motion.p
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ delay: 0.3 }}
                        className="text-gray-700 leading-relaxed md:line-clamp-3 line-clamp-4"
                      >
                        {testimonial.message}
                      </motion.p>
                    </div>
                  </div>
                </motion.div>
              </motion.div>
            ))}
          </Slider>
        </motion.div>
      </AnimatePresence>
    </motion.div>
  );
}
