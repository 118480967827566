/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
"use client";

import { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ServiceDetailsModal from "./ServiceDetailsModal";
import { useForm } from "../../context/FormContext";

const fetchourplans = async () => {
  const data = await axios.get(
    `${process.env.NEXT_PUBLIC_API_URL}/admin/package?isActive=true&sortBy=createdAt:asc`
  );
  return data.data.result.docs;
};

const sortFeatures = (features) => {
  const priorityFeatures = [
    "Plumbing",
    "Electricals",
    "Carpentry",
    "Appliances",
    "Move-in/Out",
  ];

  return [...features].sort((a, b) => {
    const getName = (feature) =>
      feature.type === "service"
        ? feature.serviceCategoryId.name
        : feature.title;

    const nameA = getName(a);
    const nameB = getName(b);

    // Management features first
    const isManagementA = nameA.toLowerCase().includes("management");
    const isManagementB = nameB.toLowerCase().includes("management");
    if (isManagementA && !isManagementB) return -1;
    if (!isManagementA && isManagementB) return 1;

    // If both are management features, sort by priority then alphabetically
    if (isManagementA && isManagementB) {
      const aPriority = priorityFeatures.indexOf(nameA);
      const bPriority = priorityFeatures.indexOf(nameB);
      if (aPriority !== -1 && bPriority !== -1) return aPriority - bPriority;
      if (aPriority !== -1) return -1;
      if (bPriority !== -1) return 1;
      return nameA.localeCompare(nameB);
    }

    // Priority features next (if not management)
    const aPriority = priorityFeatures.indexOf(nameA);
    const bPriority = priorityFeatures.indexOf(nameB);
    if (aPriority !== -1 && bPriority !== -1) return aPriority - bPriority;
    if (aPriority !== -1) return -1;
    if (bPriority !== -1) return 1;

    // Support features last
    const isSupportA = nameA.toLowerCase().includes("support");
    const isSupportB = nameB.toLowerCase().includes("support");
    if (isSupportA && !isSupportB) return 1;
    if (!isSupportA && isSupportB) return -1;

    // Sort remaining features alphabetically
    return nameA.localeCompare(nameB);
  });
};

export default function OurPlans() {
  // Add this near the top with other state declarations
  const sliderRef = useRef(null);
  const [selectedDuration, setSelectedDuration] = useState(3);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [selectedFeature, setSelectedFeature] = useState(null);

  const { openForm } = useForm();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const {
    isLoading,
    error,
    data: ourplan,
  } = useQuery({
    queryKey: ["ourplan"],
    queryFn: fetchourplans,
  });

  useEffect(() => {
    if (ourplan && ourplan[1]) {
      setSelectedPlan(ourplan[1].name);
    }
  }, [ourplan]);

  // const DurationToggle = ({ selectedDuration, onChange }) => {
  //   return (
  //     <div className="flex justify-center mb-8">
  //       <div
  //         className="bg-white/5 p-1 rounded-2xl inline-flex gap-1 md:gap-2
  //         shadow-inner text-primary"
  //       >
  //         {[3, 6, 12].map((duration) => (
  //           <button
  //             key={duration}
  //             onClick={() => onChange(duration)}
  //             className={`
  //               px-4 md:px-6 py-2 rounded-xl text-sm transition-all
  //               ${
  //                 selectedDuration === duration
  //                   ? "bg-primary text-white font-medium shadow-lg"
  //                   : "hover:bg-white/10"
  //               }
  //               ${duration === 12 ? "min-w-[80px]" : "min-w-[70px]"}
  //             `}
  //           >
  //             {duration}
  //             <span className="ml-1 text-xs md:text-sm">
  //               {duration === 1 ? "Month" : "Months"}
  //             </span>
  //           </button>
  //         ))}
  //       </div>
  //     </div>
  //   );
  // };

  const SkeletonLoader = () => (
    <div className="bg-primary300 w-full mx-auto">
      {/* Desktop Skeleton */}
      <div className="hidden lg:flex justify-center gap-6">
        {[0, 1, 2].map((index) => (
          <div
            key={index}
            className={`w-[300px] flex flex-col bg-white rounded-[20px] p-6 
              ${
                index === 1
                  ? "border-2 border-primary"
                  : "border border-gray-100"
              }`}
          >
            {/* Popular Badge for middle card */}
            {index === 1 && (
              <div
                className="absolute -top-3 left-1/2 -translate-x-1/2 
                w-24 h-5 bg-gray-200 rounded-full animate-pulse"
              />
            )}

            {/* Price Section */}
            <div className="text-center mb-4">
              <div className="w-24 h-6 bg-gray-200 rounded mb-2 mx-auto animate-pulse" />
              <div className="w-32 h-8 bg-gray-200 rounded mx-auto animate-pulse" />
            </div>

            {/* Features List */}
            <div className="flex-1 space-y-3 mb-4">
              {[1, 2, 3, 4, 5].map((item) => (
                <div key={item} className="flex items-center gap-2">
                  <div className="w-4 h-4 rounded-full bg-gray-200 flex-shrink-0 animate-pulse" />
                  <div className="flex-1 h-4 bg-gray-200 rounded animate-pulse" />
                </div>
              ))}
            </div>

            {/* Button */}
            <div className="w-full h-9 bg-gray-200 rounded-xl animate-pulse" />
          </div>
        ))}
      </div>

      {/* Mobile Skeleton */}
      <div className="lg:hidden px-4">
        <div className="bg-white rounded-[20px] p-6 h-[450px] flex flex-col">
          {/* Price Section */}
          <div className="text-center mb-4">
            <div className="w-32 h-6 bg-gray-200 rounded mb-2 mx-auto animate-pulse" />
            <div className="w-40 h-8 bg-gray-200 rounded mx-auto animate-pulse" />
          </div>

          {/* Features List */}
          <div className="flex-1 space-y-3 mb-4">
            {[1, 2, 3, 4, 5, 6].map((item) => (
              <div key={item} className="flex items-center gap-2">
                <div className="w-4 h-4 rounded-full bg-gray-200 flex-shrink-0 animate-pulse" />
                <div className="flex-1 h-4 bg-gray-200 rounded animate-pulse" />
              </div>
            ))}
          </div>

          {/* Button */}
          <div className="w-full h-9 bg-gray-200 rounded-xl animate-pulse" />
        </div>
      </div>
    </div>
  );

  // Updated desktop plan card styling
  const PlanCard = ({
    plan,
    index,
    isSelected,
    onSelect,
    onMouseLeave,
    setSelectedFeature,
    setOpenEnquiryForm,
    selectedDuration,
  }) => {
    const selectedPlanData = plan.planData.find(
      (p) => p.duration === selectedDuration
    );
    const monthlyPrice = selectedPlanData?.monthlyPrice || plan.price;
    // const totalPrice = selectedPlanData?.price || plan.price;
    const sortedFeatures = sortFeatures(plan.featureIds);

    return (
      <div
        onMouseEnter={onSelect}
        onMouseLeave={onMouseLeave}
        className={`group w-[300px] flex flex-col h-full
          ${isSelected ? "shadow-xl" : "hover:shadow-md"}
          ${
            isSelected ? "bg-primary text-white" : "bg-white hover:bg-primary/5"
          }
          rounded-[20px] p-6 relative
          ${index === 1 ? "border-2 border-primary" : "border border-gray-100"}
        `}
      >
        {index === 1 && (
          <div
            className="absolute -top-3 left-1/2 -translate-x-1/2
            bg-primary text-white px-4 py-0.5 rounded-full text-xs font-bold"
          >
            Most Popular
          </div>
        )}

        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-bold">{plan.name}</h3>
          <h2
            className={`text-2xl font-bold ${
              isSelected ? "text-white" : "text-primary"
            }`}
          >
            ₹{Math.round(monthlyPrice)}&nbsp;
            <span className="text-xs font-normal">Room/Month</span>
          </h2>
        </div>

        <div className="flex-1 space-y-2 mb-4">
          {sortedFeatures.map((feature, idx) => (
            <div key={idx} className="flex items-center gap-2 text-sm">
              <svg
                className={`w-4 h-4 flex-shrink-0 ${
                  isSelected ? "text-white" : "text-primary"
                }`}
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="flex items-center gap-1">
                {feature.type === "service"
                  ? feature.serviceCategoryId.name
                  : feature.title}
                {feature.type === "service" && feature.serviceIds && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedFeature(feature);
                    }}
                    className={`text-xs px-1.5 py-0.5 rounded-full
                      ${
                        isSelected
                          ? "bg-white/20 hover:bg-white/30"
                          : "bg-primary/10 hover:bg-primary/20"
                      }`}
                  >
                    {feature.serviceIds.length}{" "}
                    {feature.serviceIds.length === 1 ? "service" : "services"}
                  </button>
                )}
              </span>
            </div>
          ))}
        </div>

        <button
          onClick={() => setOpenEnquiryForm()}
          className={`w-full py-2 rounded-xl text-sm font-bold transition-all
            ${
              isSelected
                ? "bg-white text-primary hover:bg-gray-100"
                : "bg-primary text-white hover:bg-primary-600"
            }`}
        >
          Choose
        </button>
      </div>
    );
  };

  // Update the mobile slider card
  const MobileCard = ({
    plan,
    setSelectedFeature,
    setOpenEnquiryForm,
    selectedDuration,
  }) => {
    const selectedPlanData = plan.planData.find(
      (p) => p.duration === selectedDuration
    );
    const monthlyPrice = selectedPlanData?.monthlyPrice || plan.price;
    // const totalPrice = selectedPlanData?.price || plan.price;
    const sortedFeatures = sortFeatures(plan.featureIds);

    return (
      <div
        className="bg-primary text-white rounded-[20px] p-6 mx-2 
          flex flex-col shadow-lg no-scrollbar"
      >
        {/* Compact Price Section - Updated with duration-based pricing */}
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-bold">{plan.name}</h3>
          <h2 className="text-2xl font-bold flex items-center">
            ₹{Math.round(monthlyPrice)}
            <span className="text-sm font-normal ml-1">Room/Month</span>
          </h2>
        </div>

        {/* Features List - Compact & Scrollable */}
        <div
          className="flex-1 overflow-y-auto space-y-2.5 mb-4
          scrollbar-thin scrollbar-thumb-white/20 scrollbar-track-transparent no-scrollbar"
        >
          {sortedFeatures.map((feature, idx) => (
            <div key={idx} className="flex items-center gap-2">
              <div className="bg-white/20 rounded-full p-1 flex-shrink-0">
                <svg
                  className="w-3 h-3 text-white"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="flex items-center gap-1.5 text-sm">
                <span>
                  {feature.type === "service"
                    ? feature.serviceCategoryId.name
                    : feature.title}
                </span>
                {feature.type === "service" && feature.serviceIds && (
                  <button
                    onClick={() => setSelectedFeature(feature)}
                    className="inline-flex items-center justify-center 
                      px-2 py-0.5 text-xs font-medium rounded-full
                      bg-white/20 text-white whitespace-nowrap
                      transition-all duration-200 hover:scale-105 hover:bg-white/30"
                  >
                    {feature.serviceIds.length}{" "}
                    {feature.serviceIds.length === 1 ? "service" : "services"}
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
        {/* Button Section - Fixed height */}
        <div className="flex-none h-[60px]">
          <button
            onClick={() => setOpenEnquiryForm()}
            className="w-full bg-white text-primary py-4 rounded-2xl font-bold
              hover:shadow-lg mt-2"
          >
            Choose Plan
          </button>
        </div>
      </div>
    );
  };

  return (
    <div id="pricingplans" className="section-gap !bg-primary300">
      <div className="flex flex-col items-center mb-8">
        <h2
          className="text-3xl md:text-4xl lg:text-5xl font-bold text-primary 
          tracking-tight text-center relative mb-6"
        >
          Pricing Plans
          <div
            className="absolute -bottom-4 left-1/2 transform -translate-x-1/2 
            w-20 h-1 bg-white rounded-full"
          />
        </h2>
        {/* <DurationToggle
          selectedDuration={selectedDuration}
          onChange={setSelectedDuration}
        /> */}
      </div>

      {isLoading ? (
        <SkeletonLoader />
      ) : error ? (
        <h1 className="text-center text-red-500">{error.message}</h1>
      ) : (
        <>
          {/* Desktop Version - remove AnimatePresence */}
          <div className="hidden lg:flex gap-6 justify-center">
            {Object.values(ourplan).map((plan, index) => (
              <PlanCard
                key={index}
                plan={plan}
                index={index}
                isSelected={selectedPlan === plan.name}
                onSelect={() => setSelectedPlan(plan.name)}
                onMouseLeave={() => setSelectedPlan(ourplan[1].name)}
                setSelectedFeature={setSelectedFeature}
                setOpenEnquiryForm={openForm}
                selectedDuration={selectedDuration}
              />
            ))}
          </div>

          {/* Mobile Version */}
          <div className="lg:hidden">
            <Slider {...settings} ref={sliderRef}>
              {Object.values(ourplan).map((plan, index) => (
                <MobileCard
                  key={index}
                  plan={plan}
                  setSelectedFeature={setSelectedFeature}
                  setOpenEnquiryForm={openForm}
                  selectedDuration={selectedDuration}
                />
              ))}
            </Slider>
          </div>

          {/* Slider Controls with Animation */}
          <div className="flex lg:hidden items-center justify-center gap-3 mt-7">
            {/* Previous Button */}
            <div
              onClick={() => sliderRef.current?.slickPrev()}
              className="bg-primary w-12 h-12 flex items-center justify-center 
                cursor-pointer rounded-full shadow-lg hover:bg-primary-600 
                active:scale-95 transition-all"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="text-white"
              >
                <path
                  d="M15 19l-7-7 7-7"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>

            {/* Next Button */}
            <div
              onClick={() => sliderRef.current?.slickNext()}
              className="bg-primary w-12 h-12 flex items-center justify-center 
                cursor-pointer rounded-full shadow-lg hover:bg-primary-600 
                active:scale-95 transition-all"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="text-white"
              >
                <path
                  d="M9 5l7 7-7 7"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </>
      )}

      <ServiceDetailsModal
        isOpen={Boolean(selectedFeature)}
        onClose={() => setSelectedFeature(null)}
        services={selectedFeature?.serviceIds || []}
        featureTitle={
          selectedFeature?.type === "service"
            ? selectedFeature?.serviceCategoryId.name
            : selectedFeature?.title || ""
        }
        onContactUs={() => {
          openForm();
        }}
      />
    </div>
  );
}
